export const checkIfSuccess = (point) => point <= 35;
export const checkIfWarning = (point) => point < 60 && point > 35;
export const checkIfError = (point) => point >= 60;
// we define k = (y1 - y2)/(x1 - x2)
// also suppose that distance in x ordinate is 1
const defineSlopeFactor = (data, currIndex) => (data[currIndex][1] - data[currIndex - 1][1]) / 1;
export const defineEdgePoints = (data, index) => {
    const slopeRatio = defineSlopeFactor(data, index);
    return {
        firstPoint: ((35 - data[index - 1][1]) / slopeRatio) +
            index - 1, // x = y/k + x0
        lastPoint: ((60 - data[index - 1][1]) / slopeRatio) +
            index - 1, // x = y/k + x0
    };
};
export const defineAreas = (data) => {
    const result = {};
    let areasCount = 0;
    data.forEach((point, index) => {
        var _a, _b, _c;
        const isErrorPoint = checkIfError(point[1]);
        const isWarningPoint = checkIfWarning(point[1]);
        const isSuccessPoint = checkIfSuccess(point[1]);
        const isPrevPointSuccess = typeof ((_a = data[index - 1]) === null || _a === void 0 ? void 0 : _a[1]) === 'number' &&
            checkIfSuccess(data[index - 1][1]);
        const isPrevPointWarning = typeof ((_b = data[index - 1]) === null || _b === void 0 ? void 0 : _b[1]) === 'number' &&
            checkIfWarning(data[index - 1][1]);
        const isPrevPointError = typeof ((_c = data[index - 1]) === null || _c === void 0 ? void 0 : _c[1]) === 'number' &&
            checkIfError(data[index - 1][1]);
        if (isErrorPoint) {
            if (isPrevPointSuccess) {
                const { firstPoint, lastPoint } = defineEdgePoints(data, index);
                result[`success-${areasCount}`] = [
                    ...(result[`success-${areasCount}`] || []),
                    [firstPoint, 35],
                ];
                result[`warning-${areasCount}`] = [[firstPoint, 35], [lastPoint, 60]];
                result[`error-${areasCount + 1}`] = [[lastPoint, 60]];
            }
            if (isPrevPointWarning) {
                const { lastPoint: edgePoint } = defineEdgePoints(data, index);
                result[`error-${areasCount + 1}`] = [[edgePoint, 60]];
                result[`warning-${areasCount}`] = [
                    ...(result[`warning-${areasCount}`] || []),
                    [edgePoint, 60],
                ];
            }
            if (isPrevPointWarning || isPrevPointSuccess) {
                areasCount += 1;
            }
            result[`error-${areasCount}`] = [
                ...(result[`error-${areasCount}`] || []),
                [index, point[1]],
            ];
        }
        if (isWarningPoint) {
            if (isPrevPointError) {
                const { lastPoint: edgePoint } = defineEdgePoints(data, index);
                if (result[`error-${areasCount}`]) {
                    result[`error-${areasCount}`] = [
                        ...(result[`error-${areasCount}`] || []),
                        [edgePoint, 60],
                    ];
                    result[`warning-${areasCount + 1}`] = [
                        ...(result[`warning-${areasCount + 1}`] || []),
                        [edgePoint, 60],
                    ];
                }
            }
            if (isPrevPointSuccess) {
                const { firstPoint: edgePoint } = defineEdgePoints(data, index);
                result[`success-${areasCount}`] = [
                    ...(result[`success-${areasCount}`] || []),
                    [edgePoint, 35],
                ];
                result[`warning-${areasCount + 1}`] = [
                    ...(result[`warning-${areasCount + 1}`] || []),
                    [edgePoint, 35],
                ];
            }
            if (isPrevPointError || isPrevPointSuccess) {
                areasCount += 1;
            }
            result[`warning-${areasCount}`] = [
                ...(result[`warning-${areasCount}`] || []),
                [index, point[1]],
            ];
        }
        if (isSuccessPoint) {
            if (isPrevPointError) {
                const { firstPoint, lastPoint } = defineEdgePoints(data, index);
                result[`warning-${areasCount}`] = [[lastPoint, 35], [firstPoint, 60]];
                result[`success-${areasCount + 1}`] = [[firstPoint, 35]];
            }
            if (isPrevPointWarning) {
                const { firstPoint: edgePoint } = defineEdgePoints(data, index);
                result[`success-${areasCount + 1}`] = [[edgePoint, 35]];
                result[`warning-${areasCount}`] = [
                    ...(result[`warning-${areasCount}`] || []),
                    [edgePoint, 35],
                ];
            }
            if (isPrevPointWarning || isPrevPointError) {
                areasCount += 1;
            }
            result[`success-${areasCount}`] = [
                ...(result[`success-${areasCount}`] || []),
                [index, point[1]],
            ];
        }
    });
    return Object.assign(Object.assign({}, result), { areasCount });
};
