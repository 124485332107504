import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ReactComponent as EllipseIcon } from '@icons/custom/ellipse.svg';
import Icon from '@shared/ui/icons/Icon';
import { Body } from '@src/components/styled/Typography';
import { ContainerRow } from '@src/components/styled';
const Container = styled(ContainerRow)(props => ({
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const TradingPairName = ({ className = undefined, name, color, }) => (_jsxs(Container, { className: className, children: [_jsx(Icon, { size: 6, color: color, IconComponent: EllipseIcon }), _jsx(Body, { children: name })] }));
export default TradingPairName;
