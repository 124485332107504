import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import Button from '@shared/ui/buttons/Button';
import { ReactComponent as ListIcon } from '@icons/wolfkit-light/list-bullet-light.svg';
import { ReactComponent as SquareIcon } from '@icons/wolfkit-light/square-4-grid-light.svg';
const getSelectedStyles = (selected, theme) => selected ? ({
    boxShadow: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)',
    border: `1px solid ${theme.palette.primary.main}`,
    cursor: 'default',
    path: {
        fill: theme.palette.primary.main,
    }
}) : ({
    border: '1px solid #EFF1F3',
    path: {
        fill: theme.palette.text.secondary,
    }
});
const ViewButton = styled(Button, { shouldForwardProp: (propName) => propName !== 'selected' })(props => (Object.assign({}, getSelectedStyles(props.selected, props.theme))));
const ViewButtonsContainer = styled('div', {
    shouldForwardProp: propName => propName !== 'gap' && propName !== 'flexDirection'
})((props) => ({
    display: 'flex',
    gap: props.gap,
    flexDirection: props.flexDirection,
}));
const PortfolioViewButtons = ({ gap = 6, flexDirection = 'row', activeView, onChangeView = undefined, }) => {
    const onCardViewSelect = () => {
        if (activeView !== 'card' && onChangeView) {
            onChangeView('card');
        }
    };
    const onListViewSelect = () => {
        if (activeView !== 'list' && onChangeView) {
            onChangeView('list');
        }
    };
    return _jsxs(ViewButtonsContainer, { gap: gap, flexDirection: flexDirection, children: [_jsx(ViewButton, { variant: 'outline', color: 'primary', startIcon: SquareIcon, onClick: onCardViewSelect, selected: activeView === 'card' }), _jsx(ViewButton, { variant: 'outline', color: 'primary', startIcon: ListIcon, onClick: onListViewSelect, selected: activeView === 'list' })] });
};
export default PortfolioViewButtons;
