import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useAppSelector } from '@app/store/Hooks';
import PortfolioCard from '../PortfolioCard';
import { isUserPortfolioOwner, isUserSubsbribedToPortfolio } from '../../lib';
const ProfilePortfolioCard = ({ portfolio = undefined, viewType, selected = false, disabled = false, onAction = () => { }, onClick = () => { }, isLoading = false, }) => {
    const { currentUserProfile } = useAppSelector(state => state.profile);
    const { user } = useAppSelector(state => state.user);
    const isUserOwner = isUserPortfolioOwner(portfolio, user);
    const isUserTrader = (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.type) === 'TRADER';
    const isUserSubscribed = isUserSubsbribedToPortfolio(portfolio, user);
    const isArchived = (portfolio === null || portfolio === void 0 ? void 0 : portfolio.is_archived) || false;
    const displayState = isArchived ? 'archive' : undefined;
    const actionType = useMemo(() => {
        if (isArchived) {
            return 'view_archive';
        }
        if (isUserTrader && isUserOwner) {
            return 'edit';
        }
        if (!isUserTrader &&
            !isUserOwner &&
            !isUserSubscribed) {
            return 'subscribe';
        }
        if (!isUserTrader &&
            !isUserOwner &&
            isUserSubscribed) {
            return 'unsubscribe';
        }
        if (isUserSubscribed) {
            return 'view';
        }
        return 'none';
    }, [
        isArchived,
        isUserTrader,
        isUserOwner,
        isUserSubscribed,
    ]);
    return (_jsx(PortfolioCard, { portfolio: portfolio, variant: 'elevated', viewType: viewType, primaryActionType: actionType, selected: selected, disabled: disabled, onAction: onAction, displayState: displayState, onClick: onClick, isLoading: isLoading }));
};
export default ProfilePortfolioCard;
